import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import headerContentImage from '../assets/images/cantina-decembrina-content-header.jpg';
import contentImage from '../assets/images/cantina-decembrina-content.jpg';
// import contentImage2 from '../assets/images/cantina-decembrina-content-2.jpg';
import headerImage from '../assets/images/cantina-decembrina-content-header-image.jpg';
import leftContentImage from '../assets/images/cantina-decembrina-left-image.jpg';
import '../styles/PageCantinaDecembrina.scss';
import { fontawesome } from '../utilities';
import { Link } from 'react-router-dom';

const PageCantinaDecembrina = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main className="cantina-decembrina">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerImage} alt="Cantina Decembrina header" title="Imagen header Cantina Decembrina" />
                </div>
            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="Cantina Decembrina left content" title="Imagen izquierda Cantina Decembrina" />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=527h3vygc8mzcqp6elb7notxx1o1ku&pven=1'}><button className='btn btn-primary btn-buy-ticket'><FontAwesomeIcon icon={solidIcons['faTicket']} /> COMPRAR BOLETA</button></Link>
                                <Link to={'https://wa.link/8ni0za'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html'}><button className='btn btn-primary btn-buy-download'>DESCARGAR BOLETA</button></Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h1 className='content-container__right-content__title'>CANTINA DECEMBRINA</h1>
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="Cantina Decembrina content" title="Imagen contenido Cantina Decembrina" />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
export default PageCantinaDecembrina; 