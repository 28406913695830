import QRImage from '../assets/images/qr-wpp.png';
import headerImage from '../assets/images/aliados-header.jpg';
import logoVive from '../assets/images/logo-aliados/vive-574-x-251.png';
import logoExtra from '../assets/images/logo-aliados/Extra-boletos-574-x-251.png';
import logoGo from '../assets/images/logo-aliados/TICKET-JPG_Mesa-de-trabajo-1-copia.png';
import logoTicketMore from '../assets/images/logo-aliados/Ticket-more-574-x-251.png';
import logoGranTicket from '../assets/images/logo-aliados/logo-trans-blanco.png';
import logoMejores from '../assets/images/logo-aliados/Las-Mejores-Entradas-Logo-Blanco.png';
import logoMetro from '../assets/images/logo-aliados/logo-metroticket.png';
import logoTuboleto from '../assets/images/logo-aliados/img-002-1500x591-px2-1.png';
import logoSoloticket from '../assets/images/logo-aliados/logo-soloticket-1.png';
import '../styles/PageAliados.scss';
import { Link } from 'react-router-dom';
const PageAliados = () => {
    return (
        <main className="aliados">
            <header className='header-aliados'>
                <img className='img-fluid' src={headerImage} alt="Aliados header" title="Imagen header Aliados" />
                <div className='header-title-aliados'>
                    <h1>ALIADOS</h1>
                </div>
            </header>
            <section id="contact-section" >
                <div className="container-fluid contact-container">
                    <div className='row'>
                        <div className='col-12 col-md-9 contact-container__left-content'>
                            <h2 className='contact-container__left-content__title'>¿Te gustaría ser parte de nuestros <span>ALIADOS?</span></h2>
                            <p className='contact-container__left-content__content'><strong>¿ERES PRODUCTOR DE EVENTOS? </strong><br /> Comunícate con nosotros y te contaremos de que se trata, y como ser parte de nuestros aliados te podrá brindar grandes beneficios en tus eventos. </p>
                        </div>
                        <div className='col-12 col-md-3 contact-container__right-content'>
                            <img className='img-fluid' src={QRImage} alt="QR Whatsapp" title="QR Whatsapp" />
                            <Link to={'http://wa.link/pnxde2'}>
                                <button className='btn btn-primary btn-contact'>CONTACTO</button>
                            </Link>

                        </div>
                    </div>
                </div>
            </section>
            <section id='partners-section'>
                <div className='container-fluid partners-container'>
                    <div className='row'>
                        <div className='col-12 col-md-4 partners-container__partner'>
                            <img className='img-fluid img-large' style={{ width: '63%' }} src={logoVive} alt="Logo Vive" title="Logo Vive" />
                            <h3 className='partners-container__partner__title'>VIVEBOLETOS</h3>
                            <p className='partners-container__partner__content'>CALI</p>
                            <Link to={'https://viveboletos.com/?v=20c845c30107'}>
                                <button className='btn btn-primary btn-go-partner'>VISITAR</button>
                            </Link>
                        </div>
                        <div className='col-12 col-md-4 partners-container__partner'>
                            <img className='img-fluid img-large' style={{ width: '63%' }} src={logoExtra} alt="Logo TICKETMORE" title="Logo TICKETMORE" />
                            <h3 className='partners-container__partner__title'>EXTRA BOLETAS</h3>
                            <p className='partners-container__partner__content'>PEREIRA</p>
                            <Link to={'https://extraboletas.com/'}>
                                <button className='btn btn-primary btn-go-partner'>VISITAR</button>
                            </Link>
                        </div>
                        <div className='col-12 col-md-4 partners-container__partner'>
                            <img className='img-fluid img-large' style={{ width: '33%' }} src={logoGo} alt="Logo GOTTICKET" title="Logo GOTTICKET" />
                            <h3 className='partners-container__partner__title'>GOTTICKET</h3>
                            <p className='partners-container__partner__content'>COSTA RICA</p>
                            <Link to={'https://gotticket.cr/'}>
                                <button className='btn btn-primary btn-go-partner'>VISITAR</button>
                            </Link>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-md-4 partners-container__partner'>
                            <img className='img-fluid img-large' style={{ width: '77%' }} src={logoTicketMore} alt="Logo TICKETMORE" title="Logo TICKETMORE" />
                            <h3 className='partners-container__partner__title'>TICKETMORE</h3>
                            <p className='partners-container__partner__content'>BARRANQUILLA</p>
                            <Link to={'https://ticketmore.net/'}>
                                <button className='btn btn-primary btn-go-partner'>VISITAR</button>
                            </Link>
                        </div>
                        <div className='col-12 col-md-4 partners-container__partner'>
                            <img className='img-fluid img-large' style={{ width: '63%' }} src={logoGranTicket} alt="Logo GRANTICKET" title="Logo GRANTICKET" />
                            <h3 className='partners-container__partner__title'>GRANTICKET</h3>
                            <p className='partners-container__partner__content'>MEDELLÍN</p>
                            <Link to={'https://www.granticket.com.co/'}>
                                <button className='btn btn-primary btn-go-partner'>VISITAR</button>
                            </Link>
                        </div>
                        <div className='col-12 col-md-4 partners-container__partner'>
                            <img className='img-fluid img-large' style={{ width: '53%' }} src={logoMejores} alt="Logo LAS MEJORES ENTRADAS" title="Logo LAS MEJORES ENTRADAS" />
                            <h3 className='partners-container__partner__title'>LAS MEJORES ENTRADAS</h3>
                            <p className='partners-container__partner__content'>BOGOTÁ</p>
                            <Link to={'https://lasmejoresentradas.com/'}>
                                <button className='btn btn-primary btn-go-partner'>VISITAR</button>
                            </Link>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-md-4 partners-container__partner'>
                            <img className='img-fluid img-large' style={{ width: '77%' }} src={logoMetro} alt="Logo METROTICKET" title="Logo METROTICKET" />
                            <h3 className='partners-container__partner__title'>METROTICKET</h3>
                            <p className='partners-container__partner__content'>BARRANQUILLA</p>
                            <Link to={'https://metroticket.com.co/'}>
                                <button className='btn btn-primary btn-go-partner'>VISITAR</button>
                            </Link>
                        </div>
                        <div className='col-12 col-md-4 partners-container__partner'>
                            <img className='img-fluid img-large' style={{ width: '71%' }} src={logoTuboleto} alt="Logo TU BOLETO" title="Logo TU BOLETO" />
                            <h3 className='partners-container__partner__title'>TU BOLETO</h3>
                            <p className='partners-container__partner__content'>PANAMÁ</p>
                            <Link to={'https://www.tuboleto.com.pa/'}>
                                <button className='btn btn-primary btn-go-partner'>VISITAR</button>
                            </Link>

                        </div>
                        <div className='col-12 col-md-4 partners-container__partner'>
                            <img className='img-fluid img-large' style={{ width: '42%' }} src={logoSoloticket} alt="Logo SOLOTICKET" title="Logo SOLOTICKET" />
                            <h3 className='partners-container__partner__title'>SOLOTICKET</h3>
                            <p className='partners-container__partner__content'>BOGOTÁ</p>
                            <Link to={'https://www.soloticket.com.co/'}>
                                <button className='btn btn-primary btn-go-partner'>VISITAR</button>
                            </Link>
                        </div>
                    </div>
                </div>

            </section>
            <footer className='aliados-footer'>
                <div className='container-fluid footer-container'>
                    <div className='row'>
                        <div className='col-12 col-md-4 offset-md-2 footer-container__left-content'>
                            <Link to={'/politicas-de-privacidad'} className='mx-4'>POLÍTICAS DE PRIVACIDAD</Link>
                            <Link to={'/terminos-y-condiciones'}>TÉRMINOS Y CONDICIONES DE VENTA</Link>
                        </div>
                        <div className='col-12 col-md-4 footer-container__right-content'>
                            <p>© 2024 - QUICKTICKET. ALL RIGHTS RESERVED.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </main>
    );
}

export default PageAliados;