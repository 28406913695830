
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import headerContentImage from '../assets/images/cantina-decembrina-content-header.jpg';
import contentImage from '../assets/images/remate-cabalgata-content.jpg';
// import contentImage2 from '../assets/images/cantina-decembrina-content-2.jpg';
import headerImage from '../assets/images/remate-cabalgata-content-header-image.jpg';
import leftContentImage from '../assets/images/remate-cabalgata-left-image.jpg';
import '../styles/PageRemateCabalgata.scss';
import { fontawesome } from '../utilities';
import { Link } from 'react-router-dom';

const PageRemateCabalgata = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main className="remate-cabalgata">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerImage} alt="Remate de cabalgata Feria de Manizales 2025 header" title="Imagen header Remate de cabalgata Feria de Manizales 2025" />
                </div>
            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="Remate de cabalgata Feria de Manizales 2025 left content" title="Imagen izquierda Remate de cabalgata Feria de Manizales 2025" />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=va4d7f6xuej0mxev89oo9al2g0tb6m&pven=1'}><button className='btn btn-primary btn-buy-ticket'><FontAwesomeIcon icon={solidIcons['faTicket']} /> COMPRAR BOLETA</button></Link>
                                <Link to={'https://wa.link/8ni0za'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html'}><button className='btn btn-primary btn-buy-download'>DESCARGAR BOLETA</button></Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h1 className='content-container__right-content__title'>REMATE DE CABALGATA CON EL SEÑORAZO (2025)</h1>
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="Remate de cabalgata Feria de Manizales 2025 content" title="Imagen contenido Remate de cabalgata Feria de Manizales 2025" />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
export default PageRemateCabalgata; 