
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import headerContentImage from '../assets/images/cantina-decembrina-content-header.jpg';
import contentImage from '../assets/images/chicha-brunch-content.jpg';
// import contentImage2 from '../assets/images/chicha-brunch-content-2.jpg';
import headerImage from '../assets/images/chicha-brunch-content-header.jpg';
import leftContentImage from '../assets/images/chicha-brunch-left-image.jpg';
import '../styles/PageChichaBrunch.scss';
import { fontawesome } from '../utilities';
import { Link } from 'react-router-dom';

const PageChichaBrunch = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main className="chicha-brunch">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerImage} alt="Chicha Brunch header" title="Imagen header Chicha Brunch" />
                </div>
            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="Chicha Brunch left content" title="Imagen izquierda Chicha Brunch" />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=7ofcxflw9g9shmwxih4kptvolz6d6z&pven=1'}><button className='btn btn-primary btn-buy-ticket'><FontAwesomeIcon icon={solidIcons['faTicket']} /> COMPRAR BOLETA</button></Link>
                                <Link to={'https://wa.link/8ni0za'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html'}><button className='btn btn-primary btn-buy-download'>DESCARGAR BOLETA</button></Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h1 className='content-container__right-content__title'>Chicha Brunch</h1>
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="Chicha Brunch content" title="Imagen contenido Chicha Brunch" />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
export default PageChichaBrunch; 