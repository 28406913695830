import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import headerContentImage from '../assets/images/bucaramanga-carabobo-header.jpg';
import contentImage from '../assets/images/bucaramanga-carabobo-content.jpg';
import headerImage from '../assets/images/bucaramanga-carabobo-header.jpg';
import leftContentImage from '../assets/images/bucaramanga-carabobo-left-image.jpg';
import imageCedula from '../assets/images/bucaramanga-left-cedula-image.png';
import leftFooterContentImage from '../assets/images/bucaramanga-left-footer-image.jpeg';
import '../styles/PageBucaramangaCarabobo.scss';
import { fontawesome } from '../utilities';

const PageBucaramangaCarabobo = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main id='bucaramanga-carabobo-content' className="bucaramanga-carabobo">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerImage} alt="Bucaramanga vs Carabobo header" title="Imagen header Bucaramanga vs Carabobo" />
                </div>
            </section>
            <section id='header-image-section'>

            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="Bucaramanga vs Carabobo left content" title="Imagen izquierda Bucaramanga vs Carabobo" />
                            <img className='img-fluid' src={leftFooterContentImage} alt="Bucaramanga vs Carabobo left content" title="Imagen izquierda Bucaramanga vs Carabobo" />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=9wrk0l3lve6j0c3nzxs45wff85cymt&pven=1'}><button className='btn btn-primary btn-buy-ticket'><FontAwesomeIcon icon={solidIcons['faTicket']} /> COMPRAR</button></Link>
                                <Link to={'http://wa.link/jh91my'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html?tab_seleccionado=mis-compras'}><button className='btn btn-primary btn-buy-download'>DESCARGA TU BOLETA</button></Link>
                            </div>
                            <h2 className='title-cedula'>abono o boleta en tu cédula</h2>
                            <img className='img-fluid' src={imageCedula} alt="Bucaramanga vs Carabobo cédula" title="Imagen izquierda Bucaramanga vs Carabobo cédula" />
                            <h5 className='description-cedula'>Si posees esta cédula no debes descargar tu boleta</h5>
                            <p className='content-cedula'>Si te registraste con pasaporte, tarjeta de identidad o posees la cédula nueva, por favor descarga tu boleta para presentarla en el evento.  <br /> <br />¡Esperamos verte pronto!</p>
                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h2 className='content-container__right-content__title'>AMISTOSO INTERNACIONAL</h2>
                            <h2 className='content-container__right-content__title'>BUCARAMANGA VS CARABOBO FC</h2>
                            <img className='img-fluid content-container__right-content__content-image' src={headerContentImage} alt="Bucaramanga vs Carabobo header content" title="Imagen header Bucaramanga vs Carabobo" />
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="Bucaramanga vs Carabobo content" title="Imagen contenido Bucaramanga vs Carabobo" />
                            <div className="content-text">
                                <h2>TÉRMINOS Y CONDICIONES DEL EVENTO</h2>
                                <p className='second-title'><strong>RECUERDE:</strong></p>
                                <p>Tener en cuenta las siguientes recomendaciones para hacer que su experiencia en este evento sea agradable. </p>
                                <ul>
                                    <li><strong>Política de Armas:</strong> Está prohibido el ingreso con armas.</li>
                                    <li><strong>Política de Alimentos y Bebidas:</strong> No se permite el ingreso de alimentos y bebidas externas.</li>
                                    <li><strong>Verificación de Identidad:</strong> Aceptamos únicamente la cédula amarilla para verificar la identidad. Si posees una cédula virtual o nueva, deberás descargar y presentar tu boleta para ingresar.</li>
                                    <li><strong>Política de Reembolso:</strong> Una vez que se ha adquirido la boleta y se ha finalizado la compra, no se realizarán reembolsos.</li>
                                    <li><strong>Presentación de Boletas:</strong> Tu boleta estará vinculada a tu número de cédula, el cual deberás presentar al ingresar al estadio.</li>
                                    <li><strong>Verificación del Documento:</strong> Antes de finalizar tu registro o compra, verifica que tu número de documento esté correctamente escrito. De esto depende que tu boleta quede correctamente vinculada a tu cédula.</li>
                                </ul>

                                <h2 className='final-message'>RESPONSABLE DEL EVENTO: <br />ATLETICO BUCARAMANGA<br />NIT :890.203.822-9</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};
export default PageBucaramangaCarabobo;