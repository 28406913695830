import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/PagePoliticasDeUso.scss';
import { fontawesome } from '../utilities';
import headerImage from '../assets/images/HEADER-POLITICAS.jpg';
import { Link } from 'react-router-dom';
const PagePoliticasDeUso = () => {
    const { regularIcons } = fontawesome;
    return (
        <div className='TYC-page'>
            <div className='header-container-tyc' >
                <img className='img-fluid' src={headerImage} alt="Header tratamiento de datos" />
            </div>
            <div className="container">
                <div className="row">
                    <h2>
                        <FontAwesomeIcon icon={regularIcons['faFileAlt']} />
                        <strong>POLÍTICAS DE</strong> PRIVACIDAD
                    </h2>
                </div>
                <div className="row">
                    <h3>
                        <strong>POLÍTICA</strong> DE TRATAMIENTO DE DATOS PERSONALES QUICK TICKET SAS – Nit 900894005 – 7
                    </h3>
                </div>
                <div className="row">
                    Esta Política de Tratamiento de Datos Personales de QUICK TICKET SAS – Nit 900894005 – 7, con domicilio principal en la ciudad de Manizales – Caldas, tiene como finalidad dar aplicación a lo dispuesto en los artículos 15 y 20 de la Constitución Política de Colombia, la Ley 1266 de 2008, Ley 1581 de 2012 y Decreto Reglamentario 1377 de 2013, referentes a la recolección, almacenamiento, uso, circulación, supresión, cesión y todas aquellas actividades que constituyan el tratamiento de datos personales suministrados.
                </div>
                <div className="row">
                    <b className='m-0 p-0'>Objeto</b>
                    De acuerdo a la Ley 1581 de 2012, ley de Habeas Data, el objeto de la presente política es el de definir los lineamientos generales para la protección y tratamiento de los datos personales que seguirá QUICK TICKET SAS, como Responsable de las bases de datos que posee y utiliza para el cumplimiento de su objeto social. Así mismo, la presente política se expiden virtud del compromiso de velar por la seguridad y el uso adecuado de la información de los titulares. Además, responde al cumplimiento y a la materialización de los principios consagrados en la Ley 1581 de 2012 por parte de esta compañía como Responsable de los datos y por aquellos que sean designados como Encargados del tratamiento de los mismos.
                </div>
                <div className="row">
                    A su vez, esta política de tratamiento de la información personal, tiene por objeto desarrollar el derecho constitucional que tienen todas las personas a conocer, actualizar y rectificar la información que se haya recogido sobre ellas en bases de datos o archivos, y los demás derechos, libertades y garantías constitucionales a que se refiere el artículo 15 de la Constitución Política; así como el derecho a la información consagrado en el artículo 20 de la misma.
                </div>
                <div className="row">
                    A la luz de esta política, se entiende de manera general por “Titular” a toda persona natural cuyos datos personales son objeto de Tratamiento, y específicamente a los Clientes, Proveedores, empleados, Contratistas y terceros.
                </div>
                <div className="row">
                    <ol type="I">
                        <li style={{ 'fontSize': '1rem' }}>
                            <h6> IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO DE LA INFORMACIÓN </h6>
                            <p className='mb-4'>
                                Será responsable del tratamiento de los datos personales suministrados, QUICK TICKET SAS – Nit 900894005 – 7, ubicada en la Cra 21 64A-33, edificio Multiplaza,, correo electrónico servicioalcliente@quickticket.com.co y teléfono : 3116746137
                            </p>
                        </li>
                        <li style={{ 'fontSize': '1rem' }}>
                            <h6 > FINALIDADES DE LA RECOLECCIÓN DE LOS DATOS PERSONALES </h6>
                            <p className='mb-4' >
                                Los datos personales por usted proporcionados QUICK TICKET SAS Nit 900894005 – 7, se almacenarán en nuestras bases de datos y serán utilizados para alguna de las siguientes finalidades:
                            </p>
                            <p style={{ fontWeight: 'bold' }}>
                                Con clientes y/o posibles clientes:
                            </p>
                            <ol type="1" >
                                <li>
                                    Ejecutar la relación contractual existente.
                                </li>
                                <li>
                                    Brindar información sobre los productos y/o servicios, o sobre cambios que se presenten en los mismos.
                                </li>
                                <li>
                                    Proveer productos y/o servicios requeridos.
                                </li>
                                <li>
                                    Monitorear la trazabilidad de los productos o servicios.
                                </li>
                                <li>
                                    Evaluar la calidad del servicio.
                                </li>
                                <li>
                                    La realización de análisis estadísticos, comerciales, estratégicos, financieros y demás que conciernan al objeto social de QUICK TICKET SAS
                                </li>
                                <li>
                                    Enviar al correo físico, electrónico, celular o dispositivo móvil, vía mensajes de texto (SMS y/o MMS) o a través de cualquier otro medio análogo y/o digital de comunicación, información comercial, publicitaria o promocional sobre la maquinaria, equipos, servicios, eventos y/o promociones de tipo comercial, con el fin de impulsar, invitar, dirigir, ejecutar, informar y de manera general, llevar a cabo campañas, promociones o concursos de carácter comercial o publicitario, adelantados por QUICK TICKET SAS y/o por terceras personas.
                                </li>
                                <li>
                                    Consultar información crediticia/financiera y realizar reporte ante las centrales de riesgos que QUICK TICKET SAS considere pertinentes, para la vinculación como cliente, otorgamiento de créditos, referenciaciones y/o reporte de comportamiento y/o incumplimiento en los pagos.
                                </li>
                                <li>
                                    Transmisión y/o cesión de base de datos a terceros y/o filiales, con fines estadísticos, comerciales, estratégicos y financieros.
                                </li>
                                <li>
                                    Realizar las referenciaciones comerciales que QUICK TICKET SAS considere pertinentes para el sostenimiento, expansión y/o crecimiento.
                                </li>
                            </ol>
                            <p style={{ fontWeight: 'bold' }}>
                                Con empleados, prestadores de servicios y aspirantes:
                            </p>
                            <ol type='1'>
                                <li>
                                    Adelantar procesos de vinculación, ejecución y terminación de la relación laboral y/o de prestación de servicios.
                                </li>
                                <li>
                                    Desarrollar el proceso de selección, evaluación, vinculación laboral y/o de prestación de servicios.
                                </li>
                                <li>
                                    Realizar los procesos de afiliación a la seguridad social.
                                </li>
                                <li>
                                    Atender procesos de auditoría interna o externa.
                                </li>
                                <li>
                                    Aplicación de exámenes médico – ocupacionales.
                                </li>
                                <li>
                                    Suministrar, compartir, enviar o entregar sus datos personales a empresas filiales, vinculadas, o subordinadas de QUICK TICKET SAS, y/o terceros colaboradores, en el evento que dichas compañías requieran la información para efectos contractuales.
                                </li>
                                <li>
                                    Dar cumplimiento a la relación laboral y/o contractual existente.
                                </li>
                                <li>
                                    Enviar al correo físico, electrónico, celular o dispositivo móvil, vía mensajes de texto (SMS y/o MMS) o a través de cualquier otro medio análogo y/o digital de comunicación, circulares informativas, memorandos, programación de actividades laborales, de seguridad y salud en el trabajo o de bienestar, entre otras relacionadas con el vínculo contractual existente y el desarrollo del objeto social de QUICK TICKET SAS.
                                </li>
                            </ol>
                            <p style={{ fontWeight: 'bold' }}>
                                Con proveedores
                            </p>
                            <ol type='1'>
                                <li>
                                    Ejecutar la relación contractual existente.
                                </li>
                                <li>
                                    Contactar y contratar nueva maquinaria, equipos y/o servicios que QUICK TICKET SAS requiera para el normal funcionamiento de su operación.
                                </li>
                                <li>
                                    Suministrar, compartir, enviar, ceder y entregar sus datos personales a empresas filiales, vinculadas, o subordinadas de QUICK TICKET SAS, y/o terceros colaboradores, en el evento que dichas compañías requieran la información para sus fines.
                                </li>
                                <li>
                                    Enviar al correo físico, electrónico, celular o dispositivo móvil, vía mensajes de texto (SMSy/oMMS) o a través de cualquier otro medio análogo y/o digital de comunicación, información comercial, publicitaria o promocional sobre la maquinaria, equipos, servicios, eventos y/o promociones de tipo comercial, con el fin de impulsar, invitar, dirigir, ejecutar, informar y de manera general, llevar a cabo campañas, promociones o concursos de carácter comercial o publicitario, adelantados por QUICK TICKET SAS y/o por terceras personas
                                </li>
                                <li>
                                    Realizar las referenciaciones comerciales que QUICK TICKET SAS considere pertinentes para el sostenimiento, expansión y/o crecimiento de la sociedad.
                                </li>
                            </ol>
                        </li>
                        <li style={{ 'fontSize': '1rem' }}>
                            <h6> TRATAMIENTO DE DATOS ESPECIALES </h6>
                            <p className='mb-4'>
                                En cuanto al tratamiento de datos sensibles o datos cuyo titular sea un menor de edad QUICK TICKET SAS Adecua todas las medidas necesarias para su protección y señala lo siguiente:
                            </p>
                            <ul style={{ listStyleType: 'disc' }}>
                                <li>
                                    Datos sensibles: QUICK TICKET SAS advierte al titular de datos sensibles que tiene derecho a optar por no suministrar cualquier información sensible solicitada, relacionada, entre otros, con datos sobre su origen racial o étnico, la pertenencia a sindicatos, organizaciones sociales o de derechos humanos, convicciones políticas, religiosas, de la vida sexual, biométricos o datos de salud.
                                </li>
                                <li>
                                    Datos de menores de edad: El suministro de los datos personales cuyo titular sea menor de edad es facultativo, y debe realizarse con autorización de los padres de familia o representantes legales del menor. QUICK TICKET SAS solamente usará, almacenará y realizará tratamiento de datos personales de menores de edad que sean hos, descendientes o que dependan o estén a cargo de los empleados o contratistas de QUICK TICKET SAS u ostenten derechos emanados de la filiación con alguno de ellos, siempre que sean de naturaleza pública. La finalidad de dicho tratamiento será únicamente la de planear y realizar actividades relacionadas con el bienestar personal y familiar de los socios, empleados y los menores, la de las afiliaciones como dependiente al sistema de seguridad social y la realización de citaciones para las juntas de accionistas. Para tales efectos, QUICK TICKET SAS tendrá en cuenta el respeto y prevalencia de los derechos de los menores, su interés superior y sus derechos fundamentales.
                                </li>
                            </ul>
                        </li>
                        <li style={{ 'fontSize': '1rem' }}>
                            <h6> DERECHOS DE LOS TITULARES</h6>
                            <p className='mb-4'>
                                Los titulares de datos personales manejados por QUICK TICKET SAS por sí o por intermedio de su representante y/o apoderado o su causahabiente podrán ejercer los siguientes derechos de tal manera que se satisfagan los propósitos de ley:
                            </p>
                            <ol type='1'>
                                <li>
                                    Derecho de conocimiento: En virtud del cual podrá acceder a los datos personales que estén bajo el poder de QUICK TICKET SAS, para efectos de consultarlos, cada vez que lo consideren pertinente, o cada vez que existan modificaciones sustanciales de las Políticas de Tratamiento de la información que motiven nuevas consultas.
                                </li>
                                <li>
                                    Derecho de actualización: El titular de la información tiene derecho a actualizar sus datos personales cuando estos sean parciales, fraccionados o incompletos.
                                </li>
                                <li>
                                    Derecho de rectificación: El titular de la información tiene derecho a rectificar sus datos personales cuando aquellos son inexactos o induzcan a error.
                                </li>
                                <li>
                                    Derecho de supresión o revocación: El titular de la información podrá revocar la autorización y/o solicitar la supresión del dato cuando en el tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. No procederá la revocatoria cuando el titular tenga un deber legal o contractual de permanencia en la base de datos.
                                </li>
                                <li>
                                    Derecho a solicitar prueba del a autorización: salvo en los eventos en los cuales, según las normas legales vigentes, no se requiera de la autorización para realizar el tratamiento.
                                </li>
                                <li>
                                    Derecho a ser informado respecto del uso del dato personal.
                                </li>
                                <li>
                                    Derecho a presentar quejas ante la Superintendencia de Industria y Comercio: por infracciones a lo dispuesto en la normatividad vigente sobre tratamiento de datos personales.
                                </li>
                                <li>
                                    Acceder a la información acceder en forma gratuita a sus datos personales que hayan sido objeto de tratamiento.
                                </li>
                            </ol>
                        </li>
                        <li style={{ 'fontSize': '1rem' }}>
                            <h6 > DEBERES DEL RESPONSABLE DEL TRATAMIENTO DE LOS DATOS PERSONALES</h6>
                            <p style={{ fontWeight: 'bold' }}>
                                QUICK TICKET SAS , cuando actúe como responsable del tratamiento de datos personales, cumplirá con los siguientes deberes:
                            </p>
                            <ol>
                                <li>
                                    Garantizar al titular en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data.
                                </li>
                                <li>
                                    Solicitar y conservar la copia de la respectiva autorización otorgada por el titular.
                                </li>
                                <li>
                                    Informar debidamente al titular sobre la finalidad de la recolección y los derechos que le asisten en virtud de la autorización otorgada.
                                </li>
                                <li>
                                    Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
                                </li>
                                <li>
                                    Garantizar que la información que se suministre al encargado del tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible.
                                </li>
                                <li>
                                    Actualizar la información, comunicando de forma oportuna al encargado del tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado, y adoptar las demás medidas necesarias para que la información suministrada a este, se mantenga actualizada.
                                </li>
                                <li>
                                    Rectificar la información cuando sea incorrecta y comunicarlo pertinente al encargado del tratamiento.
                                </li>
                                <li>
                                    Tramitar las consultas y reclamos formulados.
                                </li>
                                <li>
                                    Informar a solicitud del titular sobre el uso dado a sus datos.
                                </li>
                                <li>
                                    Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los titulares.
                                </li>
                            </ol>
                        </li>

                        <li style={{ 'fontSize': '1rem' }}>
                            <h6> PROCEDIMIENTO PARA EJERCER LOS DERECHOS</h6>
                            <p className='mb-4'>
                                Para ejercer sus derechos como titular podrá contactarnos a través del correo electrónico:

                                servicioalcliente@quickticket.com

                                Para efectos de una ágil y correcta atención a la solicitud que presente, tenga en cuenta que en todos los casos deberá indicar la siguiente información.
                            </p>
                            <ol>
                                <li>
                                    Nombre e identificación del Titular.
                                </li>
                                <li>
                                    La descripción precisa y completa de los hechos que originan el trámite.
                                </li>
                                <li>
                                    La dirección física o electrónica para remitir la respuesta e informar sobre el estado del trámite.
                                </li>
                                <li>
                                    Los documentos y demás pruebas que se pretendan hacer valer en caso de que los hubiere.
                                </li>
                            </ol>
                            <p className='mb-4'>
                                Consultas: Se resolverán en un término máximo de diez (10) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible responder la consulta dentro de dicho término, se informará al interesado con dos (2) días hábiles previos al vencimiento, expresando los motivos de la demora y señalando la fecha en que se atenderá su solicitud, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer plazo.
                            </p>
                            <p className='mb-4'>
                                Reclamos: Cuando se advierta que la información contenida en una base de datos de QUICK TICKET SAS debe ser objeto de corrección, actualización, supresión, rectificación o se observe el presunto incumplimiento de cualquiera de los deberes legales, podrán presentar reclamación a través de cualquiera de los canales de comunicación señalados anteriormente. Una vez recibida la respectiva reclamación QUICK TICKET SAS contará con el término quince (15) días hábiles para resolverla, contados a partir del día siguiente a la fecha de su recibo. En caso de que QUICK TICKET SAS no sea competente para resolver el reclamo presentado, dará traslado a quien corresponda en un término máximo de dos (2) días hábiles e informará de la situación al interesado.
                            </p>
                            <p className='mb-4'>
                                Si el reclamo resulta incompleto, QUICK TICKET SAS requerirá al interesado dentro de los cinco (5) días siguientes a su recepción para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el peticionario presente la información solicitada, se entenderá que ha desistido de la reclamación.
                            </p>
                            <p className='mb-4'>
                                Una vez recibido el reclamo, QUICK TICKET SAS incluirá en la respectiva base de datos una leyenda que diga “reclamo en trámite” y el motivo del mismo, en un término no mayor a dos (2) días hábiles. Dicha leyenda se mantendrá hasta que el reclamo sea decidido.
                            </p>
                        </li>
                        <li style={{ 'fontSize': '1rem' }}>
                            <h6> CESIÓN DE DATOS</h6>
                            <p className='mb-4'>
                                Cuando por motivos contractuales los proveedores, prestadores de servicios o terceros colaboradores de QUICK TICKET SAS soliciten la cesión de la información contenida en bases de datos que maneja la empresa, deberá solicitarlo mediante documento escrito enviado en las oficinas de QUICK TICKET SAS, ubicadas en la Cra 21 64A-33, edificio multiplaza, de Manizales, Caldas, o a través del correo electrónico servicioalcliente@quickticket.com.co En referido documento deberá expresarse de manera clara e inequívoca la necesidad que motiva dicha petición, confirmará que para el uso de la información contenida en esa (s) base (s) de datos se acogerá a la política para el tratamiento de datos personales implementada por QUICK TICKET SAS, y hará saber las medidas de seguridad utilizadas una vez sea entregada la base de datos solicitada.
                            </p>
                        </li>
                        <li style={{ 'fontSize': '1rem' }}>
                            <h6> SEGURIDAD DE LA INFORMACIÓN</h6>
                            <p className='mb-4'>
                                QUICK TICKET SAS cuenta con las medidas de seguridad humana, administrativa y técnica para proteger la información de los Titulares, e impedir el acceso no autorizado a sus datos o cualquier modificación, divulgación o destrucción no autorizada de los mismos.
                            </p>
                            <p className='mb-4'>
                                El acceso a los datos personales está restringido a empleados, contratistas, representantes y agentes de QUICK TICKET SAS encargados del tratamiento de los datos personales y/o que necesitan conocer los mismos para desempeñar sus funciones y desarrollar el objeto social de la empresa.
                            </p>
                        </li>
                        <li style={{ 'fontSize': '1rem' }}>
                            <h6>VIGENCIA DE LA BASE DE DATOS.</h6>
                            <p className='mb-4'>
                                Los datos personales incorporados en la Base de Datos de QUICK TICKET SAS estarán vigentes durante el plazo necesario para cumplir sus finalidades y para permitir el cumplimiento de sus obligaciones legales y contractuales. Una vez vencidos estos plazos y según lo dispuesto en el artículo 28 de la Ley 962 de 2005, los datos reposarán por un periodo de diez (10) años contados a partir del fin de la relación con QUICK TICKET SAS, posteriormente se procederá a la eliminación de los archivos físicos y/o magnéticos que contengan la información personal, así como los datos personales que figuren en las bases de datos.
                            </p>
                            <p className='mb-4'>
                                Del plazo aquí indicado se exceptúan las bases de datos correspondientes a relaciones de tipo laboral, las cuales deberán ser conservadas por 30 años.
                            </p>
                            <p className='mb-4'>
                                Parágrafo: Una vez cumplidos los plazos aquí estipulados o los plazos dispuestos por ley para la conservación de documentos, QUICK TICKET SAS procederá con la destrucción física y o magnética de la información contenida en sus bases de datos
                            </p>
                        </li>
                        <li style={{ 'fontSize': '1rem' }}>
                            <h6> PUBLICIDAD Y VIGENCIA DE LA POLÍTICA</h6>
                            <p>
                                La presente Política de Tratamiento de Datos Personales se hará pública a través de quickticket.com.co y se entenderá vigente desde el dia 1 de Enero del 2022.
                            </p>
                        </li>
                    </ol>
                </div>
            </div>
            <footer className='aliados-footer'>
                <div className='container-fluid footer-container'>
                    <div className='row'>
                        <div className='col-12 col-md-4 offset-md-2 footer-container__left-content'>
                            <Link to={'/politicas-de-privacidad'} className='mx-4'>POLÍTICAS DE PRIVACIDAD</Link>
                            <Link to={'/terminos-y-condiciones'}>TÉRMINOS Y CONDICIONES DE VENTA</Link>
                        </div>
                        <div className='col-12 col-md-4 footer-container__right-content'>
                            <p>© 2024 - QUICKTICKET. ALL RIGHTS RESERVED.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
export default PagePoliticasDeUso;