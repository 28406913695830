import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import headerContentImage from '../assets/images/carrera-corazon-header.jpg';
import contentImage from '../assets/images/carrera-corazon-content.jpg';
import contentImage2 from '../assets/images/carrera-corazon-content2.jpg';
import headerImage from '../assets/images/carrera-corazon-header.jpg';
import leftContentImage from '../assets/images/carrera-corazon-left-image.jpg';
import imageCedula from '../assets/images/bucaramanga-left-cedula-image.png';
import leftFooterContentImage from '../assets/images/bucaramanga-left-footer-image.jpeg';
import '../styles/PageCarreraCorazon.scss';
import { fontawesome } from '../utilities';

const PageCarreraCorazon = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main id='carrera-corazon-content' className="carrera-corazon">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerImage} alt="carrera corazón header" title="Imagen header carrera corazón" />
                </div>
            </section>
            <section id='header-image-section'>

            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="carrera del corazón left content" title="Imagen izquierda carrera del corazón" />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=tcpkxoav6woru91ls7f5j3myinvtpe&pven=1'}><button className='btn btn-primary btn-buy-ticket'><FontAwesomeIcon icon={solidIcons['faTicket']} /> COMPRAR</button></Link>
                                <Link to={'http://wa.link/jh91my'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html?tab_seleccionado=mis-compras'}><button className='btn btn-primary btn-buy-download'>DESCARGA TU BOLETA</button></Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h2 className='content-container__right-content__title'>LA CARRERA DEL CORAZÓN</h2>
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="carrera del corazón content" title="Imagen contenido carrera del corazón" />
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage2} alt="carrera del corazón content" title="Imagen contenido carrera del corazón" />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};
export default PageCarreraCorazon;