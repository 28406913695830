import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import headerImage from '../assets/images/fcfc-content-header-image.jpg';
import contentImage from '../assets/images/fcfc-content-image.jpg';
import headerContentImage from '../assets/images/fcfc-header.jpg';
import leftContentImage from '../assets/images/fcfc-left-image.jpg';
import '../styles/PageFcfc.scss';
import { fontawesome } from '../utilities';
import { Link } from 'react-router-dom';

const PageFcfc = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main className="fcfc">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerImage} alt="Fútbol de salón header" title="Imagen header Fútbol de salón" />
                </div>
            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="Fútbol de salón left content" title="Imagen izquierda Fútbol de salón" />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=xie3alorngj59z7u45d2fb1sg680ml&pven=1'}><button className='btn btn-primary btn-buy-ticket'><FontAwesomeIcon icon={solidIcons['faTicket']} /> COMPRAR BOLETA</button></Link>
                                <Link to={'https://wa.link/8ni0za'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html'}><button className='btn btn-primary btn-buy-download'>DESCARGAR BOLETA</button></Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h1 className='content-container__right-content__title'>MT ORANGE F.S VS CLUB DEPORTIVO REAL NEIVA</h1>
                            <img className='img-fluid content-container__right-content__header-image' src={headerContentImage} alt="Fútbol de salón header content" title="Imagen header Fútbol de salón" />
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="Fútbol de salón content" title="Imagen contenido Fútbol de salón" />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
export default PageFcfc; 