import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import headerContentImage from '../assets/images/full-80-s-content-header-image.jpg';
import contentImage from '../assets/images/full-80-s-content.jpg';
import leftContentImage from '../assets/images/full-80-s-left-image.jpg';
import '../styles/PageFull80s.scss';
import { fontawesome } from '../utilities';
import { Link } from 'react-router-dom';

const PageFull80s = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main className="back-to-the-mt">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerContentImage} alt="FULL 80s  header" title="Imagen header FULL 80s " />
                </div>
            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="FULL 80s  left content" title="Imagen izquierda FULL 80s " />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=50fiyvlj1qccwaxni6o9a9urg2hfkb&pven=1'}><button className='btn btn-primary btn-buy-ticket'><FontAwesomeIcon icon={solidIcons['faTicket']} /> COMPRAR BOLETA</button></Link>
                                <Link to={'https://wa.link/8ni0za'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html'}><button className='btn btn-primary btn-buy-download'>DESCARGAR BOLETA</button></Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h1 className='content-container__right-content__title'>FULL 80s </h1>
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="FULL 80s  content" title="Imagen contenido FULL 80s " />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
export default PageFull80s; 