
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import headerContentImage from '../assets/images/cantina-decembrina-content-header.jpg';
import contentImage from '../assets/images/despechonato-content.png';
// import contentImage2 from '../assets/images/cantina-decembrina-content-2.jpg';
import headerImage from '../assets/images/despechonato-content-header-image.jpg';
import leftContentImage from '../assets/images/despechonato-left-image.jpg';
import '../styles/PageDespechonato.scss';
import { fontawesome } from '../utilities';
import { Link } from 'react-router-dom';

const PageDespechonato = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main className="despechonato">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerImage} alt="Despechonato Feria de Manizales 2025 header" title="Imagen header Despechonato Feria de Manizales 2025" />
                </div>
            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="Despechonato Feria de Manizales 2025 left content" title="Imagen izquierda Despechonato Feria de Manizales 2025" />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=s4jxr6ev21b56v99jmidhnklop7o1g&pven=1'}><button className='btn btn-primary btn-buy-ticket'><FontAwesomeIcon icon={solidIcons['faTicket']} /> COMPRAR BOLETA</button></Link>
                                <Link to={'https://wa.link/8ni0za'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html'}><button className='btn btn-primary btn-buy-download'>DESCARGAR BOLETA</button></Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h1 className='content-container__right-content__title'>DESPECHONATO FERIA DE MANIZALES 2025</h1>
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="Despechonato Feria de Manizales 2025 content" title="Imagen contenido Despechonato Feria de Manizales 2025" />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
export default PageDespechonato; 