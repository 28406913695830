
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import headerContentImage from '../assets/images/cantina-decembrina-content-header.jpg';
import contentImage from '../assets/images/unlock-the-game-content.jpg';
// import contentImage2 from '../assets/images/cantina-decembrina-content-2.jpg';
import headerImage from '../assets/images/unlock-the-game-content-header-image.jpg';
import leftContentImage from '../assets/images/unlock-the-game-left-image.jpg';
import '../styles/PageUnlockTheGame.scss';
import { fontawesome } from '../utilities';
import { Link } from 'react-router-dom';

const PageUnlockTheGame = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main className="unlock-the-game">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerImage} alt="Unlock The Game header" title="Imagen header Unlock The Game" />
                </div>
            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="Unlock The Game left content" title="Imagen izquierda Unlock The Game" />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=k0olc2gxxpceqkabe6f37n0hy534zb&pven=1'}><button className='btn btn-primary btn-buy-ticket'><FontAwesomeIcon icon={solidIcons['faTicket']} /> COMPRAR BOLETA</button></Link>
                                <Link to={'https://wa.link/8ni0za'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html'}><button className='btn btn-primary btn-buy-download'>DESCARGAR BOLETA</button></Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h1 className='content-container__right-content__title'>Unlock The Game</h1>
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="Unlock The Game content" title="Imagen contenido Unlock The Game" />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
export default PageUnlockTheGame; 