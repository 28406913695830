import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import headerContentImage from '../assets/images/boleteria-full-80-manizales-content-header-image.jpeg';
import contentImage from '../assets/images/boleteria-full-80-manizales-content-image.jpeg';
import headerImage from '../assets/images/boleteria-full-80-manizales-header.jpeg';
import leftContentImage from '../assets/images/boleteria-full-80-manizales-left-image.jpeg';
import '../styles/PageBoleteriaFull80Manizales.scss';
import { fontawesome } from '../utilities';
import { Link } from 'react-router-dom';

const PageBoleteriaFull80Manizales = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main className="boleteria-full-80-manizales">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerImage} alt="Full 80's Manizales header" title="Imagen header Full 80's Manizales" />
                </div>
            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="Full 80's Manizales left content" title="Imagen izquierda Full 80's Manizales" />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=atjrmzsfa1ov4ewfkwl6ob0y6q9umj&pven=1'}><button className='btn btn-primary btn-buy-ticket'><FontAwesomeIcon icon={solidIcons['faTicket']} /> COMPRAR BOLETA</button></Link>
                                <Link to={'https://wa.link/8ni0za'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html'}><button className='btn btn-primary btn-buy-download'>DESCARGAR BOLETA</button></Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h1 className='content-container__right-content__title'>Full 80's Manizales</h1>
                            <img className='img-fluid content-container__right-content__header-image' src={headerContentImage} alt="Full 80's Manizales header content" title="Imagen header Full 80's Manizales" />
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="Full 80's Manizales content" title="Imagen contenido Full 80's Manizales" />
                            <div className="content-text">
                                <h2>TERMINOS Y CONDICIONES DEL EVENTO</h2>
                                <p><strong>RECUERDE:</strong><br />tener en cuenta las siguientes recomendaciones para hacer que su experiencia en este evento sea agradable.&nbsp;</p>
                                <ul>
                                    <li>La Fiesta Full 80 se llevará a cabo en la discoteca 215 en la ciudad de Manizales, el día 21 de junio de 2024 a partir de las 8 pm.</li>
                                    <li>No se permitirá el ingreso de menores de edad. Se requerirá documento de identidad para verificar la edad de los asistentes.</li>
                                    <li>Queda estrictamente prohibido el ingreso y consumo de drogas y armas en el evento. Se realizarán controles de seguridad en la entrada para garantizar el cumplimiento de esta norma.</li>
                                    <li>Nos reservamos el derecho de admisión. En caso de comportamiento inadecuado o incumplimiento de las normas del evento, se procederá a la expulsión del infractor sin derecho a reembolso.</li>
                                    <li>No se realizarán devoluciones de dinero por la compra de boletas. En caso de cancelación del evento por motivos ajenos a nuestra voluntad, se buscará una nueva fecha para la realización del mismo y las boletas serán válidas para la nueva fecha.</li>
                                    <li>El ingreso al evento implica la aceptación de estos términos y condiciones.</li>
                                    <li>Para asegurar un excelente evento, seremos estrictos con el aforo de los asistentes y tendremos en la fiesta 600 personas.</li>
                                </ul>
                                <p className='final-message'>¡Gracias por tu interés en la Fiesta Full 80!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
export default PageBoleteriaFull80Manizales; 