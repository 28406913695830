import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import headerContentImage from '../assets/images/bucaramanga-content-header-image.jpg';
import contentImage from '../assets/images/bucaramanga-content.jpg';
import headerImage from '../assets/images/bucaramanga-header.jpg';
import leftContentImage from '../assets/images/bucaramanga-left-image.jpg';
import leftFooterContentImage from '../assets/images/bucaramanga-left-footer-image.jpeg';
import imageCedula from '../assets/images/bucaramanga-left-cedula-image.png';
import imageComunicado from '../assets/images/Comunicado.png';
import imageComunicadoBoletas100 from '../assets/images/comunicado-boletas100.jpeg';
import '../styles/PageFinalLigaBetplay2024.scss';
import { fontawesome } from '../utilities';
import { Link } from 'react-router-dom';

const PageFinalLigaBetplay2024 = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main id='final-bucaramanga-content' className="final-bucaramanga">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerImage} alt="Final liga betplay header" title="Imagen header Final liga betplay" />
                    <img src={imageComunicadoBoletas100} alt="Final liga betplay header" title="Imagen header Final liga betplay" />
                </div>
                <div className="container header-container">
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>¡ATENCIÓN! Lea antes de hacer su compra.</strong><br />

                        Para este partido, no estará habilitada la transferencia de boletas ni abonos. Por favor, tenga en cuenta que la boleta será cargada al documento que registre al momento de la compra.<br />
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                        <strong>Antes de realizar tu compra, te sugerimos tener en cuenta lo siguiente:</strong><br /> -Verifica que tu número de documento esté correctamente escrito al momento del registro. Si contiene algún error, la boleta no podrá ser cargada correctamente en tu cédula. <br />
                        - Asegúrate de digitar correctamente tus datos personales, número de teléfono y correo electrónico. Estos datos son importantes, ya que nos ayudarán a recuperar tu cuenta en caso de que sea necesario.<br />
                        - Para asegurar que todos puedan disfrutar del evento, cada cliente podrá comprar un máximo de 2 boletas, una por cédula.<br />
                        Tomar estos simples pasos te asegurará una experiencia de compra sin inconvenientes y nos permitirá brindarte el mejor servicio posible. <br /> ¡Gracias por tu confianza y preferencia!"
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                </div>
            </section>
            <section id='header-image-section'>

            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="Final liga betplay left content" title="Imagen izquierda Final liga betplay" />
                            <img className='img-fluid' src={leftFooterContentImage} alt="Final liga betplay left content" title="Imagen izquierda Final liga betplay" />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'#'}><button className='btn btn-primary btn-buy-ticket' disabled><FontAwesomeIcon icon={solidIcons['faTicket']} /> AGOTADO</button></Link>
                                <Link to={'http://wa.link/jh91my'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html?tab_seleccionado=mis-compras'}><button className='btn btn-primary btn-buy-download'>DESCARGA TU BOLETA</button></Link>
                            </div>
                            <h2 className='title-cedula'>abono o boleta en tu cédula</h2>
                            <img className='img-fluid' src={imageCedula} alt="Final liga betplay cédula" title="Imagen izquierda Final liga betplay cédula" />
                            <h5 className='description-cedula'>Si posees esta cédula no debes descargar tu boleta</h5>
                            <p className='content-cedula'>Si te registraste con pasaporte, tarjeta de identidad o posees la cédula nueva, por favor descarga tu boleta para presentarla en el evento.  <br /> <br />¡Esperamos verte pronto!</p>
                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h2 className='content-container__right-content__title'>¡SOÑEMOS JUNTOS!</h2>
                            <h2 className='content-container__right-content__title'>LIGA BETPLAY FINAL</h2>
                            <h2 className='content-container__right-content__title'>ATLETICO BUCARAMANGA VS SANTAFE</h2>
                            <img className='img-fluid content-container__right-content__content-image' src={headerContentImage} alt="Final liga betplay header content" title="Imagen header Final liga betplay" />
                            <div className='alert alert-info' role='alert'>
                                <strong>Aviso Importante: Restricción de Edad para Tribunas Norte Alta, Sur Alta y Sur Baja</strong> <br />tenga en cuenta que las tribunas Norte Alta, Sur Alta y Sur Baja están restringidas para menores de 18 años. Si compra boletas para estas localidades, no se permitirá el ingreso de menores de edad.
                            </div>
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="Final liga betplay content" title="Imagen contenido final liga betplay" />
                            <div className="content-text">
                                <h2>TÉRMINOS Y CONDICIONES DEL EVENTO</h2>
                                <p className='second-title'><strong>RECUERDE:</strong></p>
                                <p>Tener en cuenta las siguientes recomendaciones para hacer que su experiencia en este evento sea agradable. </p>
                                <ul>
                                    <li><strong>Política de Armas:</strong> Está prohibido el ingreso con armas.</li>
                                    <li><strong>Política de Alimentos y Bebidas:</strong> No se permite el ingreso de alimentos y bebidas externas.</li>
                                    <li><strong>Verificación de Identidad:</strong> Aceptamos únicamente la cédula amarilla para verificar la identidad. Si posees una cédula virtual o nueva, deberás descargar y presentar tu boleta para ingresar.</li>
                                    <li><strong>Política de Reembolso:</strong> Una vez que se ha adquirido la boleta y se ha finalizado la compra, no se realizarán reembolsos.</li>
                                    <li><strong>Presentación de Boletas:</strong> Tu boleta estará vinculada a tu número de cédula, el cual deberás presentar al ingresar al estadio.</li>
                                    <li><strong>Verificación del Documento:</strong> Antes de finalizar tu registro o compra, verifica que tu número de documento esté correctamente escrito. De esto depende que tu boleta quede correctamente vinculada a tu cédula.</li>
                                </ul>

                                <h2 className='final-message'>RESPONSABLE DEL EVENTO: <br />ATLETICO BUCARAMANGA<br />NIT :890.203.822-9</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};
export default PageFinalLigaBetplay2024;