
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import headerContentImage from '../assets/images/andina-fest-content-header.jpg';
import contentImage from '../assets/images/andina-fest-content.jpg';
import contentImage2 from '../assets/images/andina-fest-content2.jpg';
import headerImage from '../assets/images/andina-fest-header.jpg';
import leftContentImage from '../assets/images/andina-fest-left-image.jpg';
import '../styles/PageAndinaFest.scss';
import { fontawesome } from '../utilities';
import { Link } from 'react-router-dom';    

const PageAndinaFest = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main className="andina-fest">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerImage} alt="Andina Fest header" title="Imagen header Andina Fest" />
                </div>
            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="Andina Fest left content" title="Imagen izquierda Andina Fest" />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=6jgl61cb9md4uyis4i9mzovno80aax&pven=1'}><button className='btn btn-primary btn-buy-ticket'><FontAwesomeIcon icon={solidIcons['faTicket']} /> COMPRAR BOLETA</button></Link>
                                <Link to={'https://wa.link/8ni0za'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html'}><button className='btn btn-primary btn-buy-download'>DESCARGAR BOLETA</button></Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h1 className='content-container__right-content__title'>Andina Fest</h1>
                            <h2 className='content-container__right-content__title'>Apertura de puertas: 4:00 pm.</h2>
                            <h2 className='content-container__right-content__title'>Inicio de evento: 6:00 pm</h2>
                            <img className='img-fluid content-container__right-content__header-image' src={headerContentImage} alt="Andina Fest header content" title="Imagen header Andina Fest" />
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage2} alt="Andina Fest content" title="Imagen contenido Andina Fest" />
                        
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
export default PageAndinaFest; 