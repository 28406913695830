import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/PagePoliticasDeUso.scss';
import { fontawesome } from '../utilities';
import headerImage from '../assets/images/HEADER-POLITICAS.jpg';
import { Link } from 'react-router-dom';
const PageTerminosyCondiciones = () => {
    const { regularIcons } = fontawesome;
    return (
        <div className='TYC-page'>
            <div className='header-container-tyc' >
                <img className='img-fluid' src={headerImage} alt="Header tratamiento de datos" />
            </div>
            <div className="container">
                <div className="row">
                    <h2>
                        <FontAwesomeIcon icon={regularIcons['faFileAlt']} />
                        <strong>TÉRMINOS Y CONDICIONES</strong> DE USO DE LA PLATAFORMA.
                    </h2>
                </div>
                <div className="row">
                    <h3>
                        Quick Ticket SAS NIT: 900894005-7
                    </h3>
                </div>
                <div className="row">
                    El Sistema Integrado de Boletería y Abonos (En adelante SIBA) es un sistema de información encargado de la gestión total de la comercialización e impresión de boletas para espectáculos de toda índole que solicite asistencia masiva de público con control de ingreso.
                </div>
                <div className="row">

                    A continuación se describen las condiciones que se aplican al uso del sitio web quickticket.com.co y/o el software SIBA: Sistema Integrado de Boletería y Abonos (en adelante se denominarán conjuntamente LA PLATAFORMA o simplemente el sitio).
                </div>
                <div className="row">
                    <b className='m-0 p-0'>Propietario de la plataforma:</b>
                    Tanto el sitio web como el(los) software en web o aplicación web que contiene son propiedad de QUICK TICKET S.A.S. (en adelante LA COMPAÑÍA) empresa colombiana radicada en la Cra 21 64A 33 Ed Multiplaza Cable, Manizales, departamento de Caldas, Colombia; identificada con NIT. 900.894.005-7. A toda persona que acceda o use la plataforma y las aplicaciones web contenidas en este sitio se les denominará usuario(s).
                </div>
                <div className="row">
                    En el momento que el usuario accede a nuestro sitio web o utiliza las prestaciones y servicios de nuestra plataforma, declara que acepta las condiciones descritas en este documento. Dichos términos y condiciones son susceptibles de modificación, que en cualquier caso se verá reflejado públicamente en los presentes términos y condiciones.
                </div>
                <div className="row">
                    Nuestra plataforma no le exige al usuario ningún tipo de registro, inscripción o creación de cuenta para poder ingresar o usar sus servicios, ni para adquirir boletas o abonos para eventos masivos, pero será necesario que nos suministre algunos datos para realizar la facturación, de conformidad con la ley colombiana y en particular, en lo que respecta a privacidad y gestión de datos personales, nuestra plataforma se acoge a la ley 1581.
                </div>
                <div className="row">
                    <b className='m-0 p-0'>Servicio de la plataforma: </b>
                    A través de su plataforma, QUICK TICKET ofrece un servicio en línea por medio del cual el usuario puede comprar boletas, abonos o entradas electrónicas para eventos masivos incluyendo pero sin limitarse: conciertos, fiestas, festivales, excursiones, obras teatrales, encuentros deportivos etc. El usuario acepta que el valor de este servicio se incluye en el precio del producto o derecho adquirido y pagado.
                </div>
                <div className="row">
                    <b className='m-0 p-0'>Privacidad: </b>
                    LA COMPAÑÍA protege sus datos personales de conformidad con la ley 1581 de 2012 Por la cual se dictan disposiciones generales para la protección de datos personales. Nuestra plataforma procesa la información personal que el usuario facilite para permitir la utilización del Servicio de la plataforma. En lo que respecta a sus derechos con relación al manejo de sus datos personales, encontrará más información en la Política de privacidad de nuestro sitio web.
                </div>

                <div className="row">
                    Las compras realizadas por medio de nuestra plataforma, al tratarse de compras virtuales, están sujetas a la verificación, y aceptación de la tarjeta débito/crédito por parte de la pasarela de pago y/o entidad financiera que use para tal fin, y en todo caso tendrá sus propios términos y condiciones, así como su propia política de privacidad, el usuario acepta y comprende que los datos personales que ingresa en el proceso de pago son responsabilidad de la pasarela de pago o entidad financiera.
                </div>
                <div className="row">
                    <b className='m-0 p-0'>Uso personal:  </b>
                    El usuario acepta que los servicios de la plataforma están diseñados para un uso personal. No debe hacer un uso indebido de la Plataforma, ante el uso indebido de la misma, como el uso de scripts, bots o software automatizados y demás métodos para ganar prioridad en la compra de entradas o alterar el funcionamiento de la plataforma podrá dar lugar al bloqueo definitivo del Usuario.
                </div>
                <div className="row">
                    <b className='m-0 p-0'>Responsabilidad: </b>
                    El USUARIO acepta y comprende que la responsabilidad sobre la realización de eventos para los cuales se adquiere la boleta o abono es total y únicamente del empresario o realizador del evento, que se identifica en la boleta desde el momento de la oferta al público.
                </div>
                <div className="row">
                    El USUARIO acepta y comprende que la realización de cualquier evento o espectáculo, del que adquiera tiquetes por la plataforma no depende de QUICK TICKET S.A.S., quien en ningún caso será responsable por horarios, condiciones de seguridad, ubicaciones, realización, organización, calidad del contenido o ejecución del evento, ni por perjuicios patrimoniales o extrapatrimoniales sufridos en el lugar y momento de evento; LA COMPAÑÍA no será responsable de eventualidades que por cualquier motivo ajeno a LA PLATAFORMA impidan o aplacen la realización del evento para el cual se adquirió la boleta o el abono.
                </div>
                <div className="row">
                    El USUARIO acepta y asume toda la responsabilidad de verificar durante el procedimiento de compra, los datos del evento y contenido del mismo, el número de boletas, ubicaciones, restricciones, accesibilidad de menores, valor de las boletas o abonos, fechas y horas del evento. LA COMPAÑÍA no se compromete a reembolsar boletas o abonos comprados por error.
                </div>
                <div className="row">
                    <b className='m-0 p-0'>Mantenimiento de la plataforma:</b>
                    LA COMPAÑÍA toma todas las medidas posibles para garantizar un funcionamiento seguro y adecuado de la Plataforma, no podemos garantizar la exactitud del Servicio de la plataforma ni el contenido de esta.
                </div>
                <div className="row">
                    LA COMPAÑÍA no se responsabiliza de las consecuencias de (cualquier) inexactitud (como la descripción errónea de un evento), con respecto al Servicio de la plataforma. Con la finalidad de realizar mantenimiento o para cualquier otro LA COMPAÑÍA podrá suspender o limitar (temporalmente) su plataforma o alguna de sus funciones, así como retirar alguna oferta, producto, boleta, entrada, ticket o abono de la plataforma sin previo aviso, sin dar razones y sin que ello implique derecho de compensación alguno por parte de LA COMPAÑÍA.
                </div>

                <div className="row">
                    <b className='m-0 p-0'>Menores:</b>
                    Nuestra plataforma está diseñada para ser usada por mayores de 18 años, si el usuario es menor de edad debe abstenerse de usar nuestros servicios, nuestra compañía no se hace responsable por las compras realizadas por personas que no cumplan con este requisito.
                </div>
                <div className="row">
                    El acceso de menores de edad a los eventos masivos puede eventualmente estar total o parcialmente restringido, en algunos casos, el acceso a menores de 18 años podrá estar limitado a algunas ubicaciones específicas en el evento o estar condicionado al acompañamiento de un adulto, casos en los cuáles dichas restricciones se publicarán a manera de aviso junto con la información del evento en nuestro sitio web.
                </div>
                <div className="row">
                    <b className='m-0 p-0'>Servicio de pagos:</b>
                    Los servicios de procesamiento de pagos relativos a las ventas de entradas, tickets, boletas o abonos en LA PLATAFORMA son suministrados por TUCOMPRA PAYMENT, el USUARIO acepta y comprende que dicha pasarela de pagos tiene sus propios Términos y Condiciones, así como su propia política de privacidad, las cuales podrá consultar en la parte inferior del sitio web del siguiente enlace https://tucompra.com.co/. Para permitir que TUCOMPRA PAYMENT suministre efectivamente sus servicios de procesamiento de pagos, usted acepta proporcionar a LA COMPAÑÍA información exacta y completa sobre usted, y autoriza a LA COMPAÑÍA a compartirla, así como los datos de transacciones relativos a su utilización de los servicios de procesamiento de pagos con la citada pasarela de pagos.
                </div>
                <div className="row">
                    <b className='m-0 p-0'>Usos prohibidos:</b>
                    Como condición para la utilización del Servicio de la plataforma, usted acepta no ofrecer ninguna información, datos, ni contenidos a través de la Plataforma que pudieran infringir cualquier norma de orden público vigente.
                </div>

                <div className="row">
                    Asimismo, el usuario comprende que está prohibido:
                </div>
                <div className='row'>
                    <ol type="1" >
                        <li>
                            Usar de forma inadecuada o en perjuicio de terceros material o información del sitio web;
                        </li>
                        <li>
                            Acceder a áreas restringidas/protegidas de la Plataforma;
                        </li>
                        <li>
                            Reenviar cualquier virus, troyano, gusano, correo basura, spam, cadenas de mensajes, ofertas no solicitadas ni publicidad de ningún tipo ni para ningún fin;
                        </li>
                        <li>
                            Poner a prueba la vulnerabilidad de la Plataforma o de cualquier otro servicio o red relacionados
                        </li>
                        <li>
                            Infringir los sistemas de seguridad y autenticación;
                        </li>
                        <li>
                            Usurpar la identidad de otra(s) persona(s) al usar la plataforma.
                        </li>
                        <li>
                            Usar scripts, programas (parcialmente) automatizados u otros métodos para conseguir prioridad en las compras.
                        </li>
                    </ol>
                </div>
                <div className="row">
                    *Si detectase cualquier vulnerabilidad en materia de seguridad, visite esta página.
                </div>
                <div className="row">
                    Si LA COMPAÑÍA sospechara que usted no respeta los Términos y Condiciones de la plataforma, podría negársele de manera permanente el acceso a la Plataforma y a sus servicios, por medio de un bloqueo a sus datos personales y con efecto inmediato. En caso de incumplimiento nos reservamos el derecho de mantener almacenados sus datos públicos de identificación.
                </div>
                <div className="row">
                    <b className='m-0 p-0'>Divulgación de información:</b>
                    LA COMPAÑÍA tendrá derecho en todo momento a divulgar información para fines comerciales con autorización del titular de los datos, o sin autorización alguna cuando sea para:
                </div>
                <div className='row'>
                    <ol type="1" >
                        <li>
                            Defenderse en procesos judiciales
                        </li>
                        <li>
                            Acatar una orden judicial o de autoridad administrativa
                        </li>
                        <li>
                            Cumplir con una norma de orden público
                        </li>
                        <li>
                            Proteger la seguridad nacional, la defensa, la seguridad y la salud públicas
                        </li>
                        <li>
                            Proteger la vida o enfrentar eventualidades que la pongan en riesgo, de conformidad con las disposiciones de la ley 1581.
                        </li>
                    </ol>
                </div>
                <div className="row">
                    <b className='m-0 p-0'>Procedimiento para quejas:</b>
                    Si tiene alguna queja con respecto a la Plataforma o del Servicio de la plataforma, LA COMPAÑÍA desea ser informada al respecto. Por favor envíenos su queja (con una explicación lo más detallada posible) al correo servicioalcliente@quickticket.com.co.
                </div>
                <div className="row">
                    <b className='m-0 p-0'>Servicios de YouTube:</b>
                    En algunas de nuestras páginas presentamos contenidos de vídeo proporcionados por YouTube, a través de los Servicios API de YouTube. Por tanto, al utilizar LA PLATAFORMA usted acepta los Términos de Servicio de YouTube.
                </div>
                <div className="row">
                    <b className='m-0 p-0'>Boleta electrónica y/o ticket digital: </b>
                    Eventualmente, la plataforma le ofrecerá la posibilidad de comprar tickets, entradas o abonos en formato digital para algunos eventos públicos, dependiendo de decisiones del organizador del evento, podrán éstas ser el único tipo de boletas disponibles para el mismo. Estas se envían automáticamente  al correo electrónico que el usuario registre en el momento de la compra. De presentarse alguna novedad, error o inconveniente con este tipo de tiquetes, podrá contactarnos a servicioalcliente@quickticket.com.co para reportar la situación y obtener soporte de nuestro personal.
                </div>
                <div className="row">
                    <b className='m-0 p-0'>Devoluciones:</b>
                    Si el(los) organizador(es) decide(n) cancelar definitivamente el evento y procede(n) a devolver el dinero, LA COMPAÑÍA publicará en su sitio web el proceso de devolución y le notificará por medio del correo electrónico indicándole los documentos requeridos para realizar dicho reembolso. Se aclara que EL USUARIO tiene un plazo máximo de 8 días hábiles para realizar dicho proceso, luego de ser publicada la información. Pasado el tiempo LA COMPAÑÍA no se hace responsable por dicha devolución. El reembolso del dinero de las entradas adquiridas se realizará excluyendo el valor del servicio y los métodos de envío prestados por LA PLATAFORMA.
                </div>
                <div className="row">
                    El USUARIO comprende y acepta que, en el momento en que retire o reciba sus entradas, él es el único responsable de las mismas, se recomienda que las guarde en un lugar seguro, lejos del calor, humedad o de la luz solar.
                </div>
                <div className="row">
                    <b className='m-0 p-0'>Retracto:</b>
                    El retracto es un derecho consagrado en el artículo 47 de la ley 1480 Estatuto del Consumidor, por medio del cual se le concede al consumidor final de productos o servicios adquiridos por medios no tradicionales, a solicitar en los primeros cinco (5) días, posteriores a la compra, la reversión total o parcial de la compra y la consecuente devolución del dinero pagado. Este derecho no podrá ser ejercido cuando ya se hubiera utilizado la boleta o abono, o se estuviera ya ejecutando el evento para el cual se adquirió, o hubiera pasado la fecha del mismo. En estos casos, el consumidor que desee ejercer este derecho, deberá enviar una solicitud, dentro de los términos antes descritos, al correo servicioalcliente@quickticket.com.co, adjuntando el certificado bancario de la cuenta a la que desee que se realice el reembolso, indicando expresamente que solicita un reembolso por concepto de RETRACTO, indicando además los siguientes datos:
                </div>
                <div className='row'>
                    <ol type="a" >
                        <li>
                            Nombre titular de la cuenta
                        </li>
                        <li>
                            Tipo de documento
                        </li>
                        <li>
                            Número de documento
                        </li>
                        <li>
                            Dirección
                        </li>
                        <li>
                            Teléfono
                        </li>
                        <li>
                            País
                        </li>
                        <li>
                            Departamento
                        </li>
                        <li>
                            Ciudad
                        </li>
                        <li>
                            Entidad Bancaria
                        </li>
                        <li>
                            Tipo de cuenta
                        </li>
                        <li>
                            Número de cuenta bancaria
                        </li>
                        <li>
                            Número de la Boleta/Abono
                        </li>

                    </ol>
                </div>
                <div className="row">
                    Los datos indicados en la solicitud deben coincidir con los datos del certificado bancario, LA COMPAÑÍA no se responsabiliza por perjuicios derivados de información errónea, incompleta o desactualizada enviada por el solicitante del retracto.
                </div>
            </div>
            <footer className='aliados-footer'>
                <div className='container-fluid footer-container'>
                    <div className='row'>
                        <div className='col-12 col-md-4 offset-md-2 footer-container__left-content'>
                            <Link to={'/politicas-de-privacidad'} className='mx-4'>POLÍTICAS DE PRIVACIDAD</Link>
                            <Link to={'/terminos-y-condiciones'}>TÉRMINOS Y CONDICIONES DE VENTA</Link>
                        </div>
                        <div className='col-12 col-md-4 footer-container__right-content'>
                            <p>© 2024 - QUICKTICKET. ALL RIGHTS RESERVED.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
export default PageTerminosyCondiciones;