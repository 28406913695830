import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import headerContentImage from '../assets/images/panaca-viajero-content-header-image.jpeg';
import contentImage from '../assets/images/panaca-viajero-content.png';
import headerImage from '../assets/images/panaca-viajero-header.jpeg';
import leftContentImage from '../assets/images/panaca-viajero-left-image.jpeg';
import footerImage from '../assets/images/panaca-viajero-content-footer-image.png';
import '../styles/PagePanacaViajero.scss';
import { fontawesome } from '../utilities';
import { Link } from 'react-router-dom';

const PagePanacaViajero = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main id='panaca-viajero-content' className="panaca-viajero">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerImage} alt="PANACA viajero header" title="Imagen header PANACA viajero" />
                </div>
            </section>
            <section id='header-image-section'>
                <div className="container header-container">
                    <img src={headerContentImage} alt="PANACA viajero header content" title="Imagen header PANACA viajero" />
                </div>
            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="PANACA viajero left content" title="Imagen izquierda PANACA viajero" />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=4dljtmby7udok6if2c058venrszvho&pven=1'}><button className='btn btn-primary btn-buy-ticket'> COMPRAR BOLETA</button></Link>
                                <Link to={'https://wa.me/message/HJ6X5OYH64TFP1'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html'}><button className='btn btn-primary btn-buy-download'>DESCARGA TU BOLETA</button></Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h2 className='content-container__right-content__title'>COMPRA TU BOLETO</h2>
                            <h2 className='content-container__right-content__title'>INGRESO GENERAL</h2>
                            <div className='d-flex justify-content-center w-100'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=4dljtmby7udok6if2c058venrszvho&pven=1'}><button className='btn btn-primary btn-buy-ticket'> COMPRAR BOLETA</button></Link>
                            </div>
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="PANACA viajero content" title="Imagen contenido PANACA viajero" />
                            <div className="content-text">
                                <h2>DESCRIPCIÓN</h2>
                                {/* <p><strong>RECUERDE:</strong><br />tener en cuenta las siguientes recomendaciones para hacer que su experiencia en este evento sea agradable.&nbsp;</p>
                                <ul>
                                    <li>La Fiesta Full 80 se llevará a cabo en la discoteca 215 en la ciudad de Manizales, el día 21 de junio de 2024 a partir de las 8 pm.</li>
                                    <li>No se permitirá el ingreso de menores de edad. Se requerirá documento de identidad para verificar la edad de los asistentes.</li>
                                    <li>Queda estrictamente prohibido el ingreso y consumo de drogas y armas en el evento. Se realizarán controles de seguridad en la entrada para garantizar el cumplimiento de esta norma.</li>
                                    <li>Nos reservamos el derecho de admisión. En caso de comportamiento inadecuado o incumplimiento de las normas del evento, se procederá a la expulsión del infractor sin derecho a reembolso.</li>
                                    <li>No se realizarán devoluciones de dinero por la compra de boletas. En caso de cancelación del evento por motivos ajenos a nuestra voluntad, se buscará una nueva fecha para la realización del mismo y las boletas serán válidas para la nueva fecha.</li>
                                    <li>El ingreso al evento implica la aceptación de estos términos y condiciones.</li>
                                    <li>Para asegurar un excelente evento, seremos estrictos con el aforo de los asistentes y tendremos en la fiesta 600 personas.</li>
                                </ul> */}
                                <p className='second-title'><strong>¿Qué es <br /> PANACA Viajero?</strong></p>
                                <p>PANACA Viajero es una muestra del campo en la ciudad y es una experiencia de diversión totalmente interactiva, en la que se promueve su filosofía: Sin campo no hay ciudad®.</p>
                                <p>Es así como PANACA busca acercar a los colombianos al mundo rural, sembrando en grandes y chicos el amor por el campo, con diferentes animales de la zoología doméstica y las zonas interactivas, en las que los visitantes podrán vivir una experiencia única enmarcada en el maravilloso mundo agropecuario de nuestro país.</p>
                                <p> <br />Esta experiencia reúne:</p>
                                <ol>
                                    <li>
                                        <strong>Aulas</strong> Vivas: A través de la metodología del “Aprender Haciendo” se abordan una variedad de temas diseñados para que personas de todas las edades disfruten de una aventura de conexión con el campo diversa e inolvidable. Entre ellas se encuentran:
                                    </li>
                                </ol>
                                <ul>
                                    <li>Botica de la abuela</li>
                                    <li>De la tierra al alimento</li>
                                    <li>El campo hecho arte</li>
                                    <li>Los sabores del campo</li>
                                </ul>
                                <ol start={2}>
                                    <li>
                                        <strong>Presentaciones:</strong> PANACA Viajero Bogotá ofrece presentaciones, con una puesta en escena donde se resaltan las costumbres rurales y el bienestar animal, con muestras de las estaciones de Ganadería, Caninos y Equinos, además de todo el talento de los trovadores.
                                    </li>
                                    <li>
                                        <strong>Interactividad:</strong> recorre nuestros corrales, interactúa con los animales y permítete apreciarlos, aprendiendo de sus características y comportamientos, además de llevarte hermosas fotos de recuerdo de un día
                                    </li>
                                </ol>
                                <p>Además, disfruta:</p>
                                <ul>
                                    <li>Todo esto se vivirá de la mano de la “Granjera PANACA” que comparte todo su conocimiento y orienta a los visitantes para que se conecten de manera interactiva y divertida con el mundo rural.</li>
                                    <li>Carrusel de ponis: para que los más pequeños disfruten de una experiencia directa con estos hermosos animales. Recuerda que podrán acceder a este espacio los niños que pesen 30 kilos o menos.</li>
                                    <li>zonas para la diversión de todos los integrantes de la familia, disfruta de deliciosos alimentos en el mall de comidas, diviértete en la zona de juegos, comparte con amigos alrededor de bebidas artesanales y sobre todo, llévate los mejores recuerdos en una experiencia llena de momentos diseñados para un día de sonrisas y mucha diversión.</li>
                                </ul>
                                <h2 className='final-message'>RESPONSABLE DEL EVENTO: <br />Show + S.A.S.<br />900.960.245-0</h2>
                            </div>
                            <img className='img-fluid content-container__right-content__footer-image' src={footerImage} alt="PANACA viajero footer content" title="Imagen footer PANACA viajero" />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
export default PagePanacaViajero; 