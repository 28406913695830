import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import headerContentImage from '../assets/images/back-to-the-mt-content-header-image.jpg';
import contentImage from '../assets/images/back-to-the-mt-content.jpg';
import contentImage2 from '../assets/images/back-to-the-mt-content-2.jpg';
import headerImage from '../assets/images/back-to-the-mt-header.jpg';
import leftContentImage from '../assets/images/back-to-the-mt-left-image.jpg';
import '../styles/PageBackToTheMT.scss';
import { fontawesome } from '../utilities';
import { Link } from 'react-router-dom';

const PageBackToTheMT = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main className="back-to-the-mt">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerImage} alt="Back to the mini tk header" title="Imagen header Back to the mini tk" />
                </div>
            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="Back to the mini tk left content" title="Imagen izquierda Back to the mini tk" />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=c5rh02us25q9g8wcwzojpmilnb734r&pven=1'}><button className='btn btn-primary btn-buy-ticket'><FontAwesomeIcon icon={solidIcons['faTicket']} /> COMPRAR BOLETA</button></Link>
                                <Link to={'https://wa.link/8ni0za'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html'}><button className='btn btn-primary btn-buy-download'>DESCARGAR BOLETA</button></Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h1 className='content-container__right-content__title'>Back to the mini tk</h1>
                            <img className='img-fluid content-container__right-content__header-image' src={headerContentImage} alt="Back to the mini tk header content" title="Imagen header Back to the mini tk" />
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="Back to the mini tk content" title="Imagen contenido Back to the mini tk" />
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage2} alt="Back to the mini tk content" title="Imagen contenido Back to the mini tk" />
                            <div className="content-text">
                                <h2>Términos y Condiciones para la Fiesta "Back de Mini TK" en Halloween - 25 de Octubre</h2>
                                <p><strong>RECUERDE:</strong><br />tener en cuenta las siguientes recomendaciones para hacer que su experiencia en este evento sea agradable.&nbsp;</p>
                                <ol type="1">
                                    <li><b>Edad Mínima de Ingreso: </b> e prohíbe el ingreso a menores de edad. Solo se permitirá la entrada a personas mayores de 18 años. Es obligatorio presentar un documento de identidad válido en la entrada.</li>
                                    <li><b>Prohibición de Armas: </b> No se permitirá el ingreso de armas de ningún tipo. Los asistentes estarán sujetos a revisiones de seguridad en la entrada.</li>
                                    <li><b>Política de Devolución de Dinero: </b> En caso de cancelación del evento, la devolución del dinero se realizará en un máximo de 15 días después de la fecha de cancelación. Para solicitar la devolución, es necesario presentar el comprobante de compra.</li>
                                    <li><b>Ingreso de Mujeres y Personas con Discapacidad: </b> Las mujeres y personas con discapacidad ingresan al evento bajo su propia responsabilidad. Se recomienda que las personas con discapacidad asistan con un acompañante para su mayor comodidad y seguridad.</li>
                                    <li><b>Responsabilidad del Acompañante: </b> Las personas acompañantes de mujeres y personas con discapacidad son responsables de velar por su bienestar y seguridad durante el evento.</li>
                                    <li><b>Conducta Apropiada: </b> Se espera que todos los asistentes mantengan una conducta apropiada y respetuosa. Cualquier comportamiento disruptivo o peligroso resultará en la expulsión inmediata del evento sin derecho a reembolso.</li>
                                    <li><b>Derecho de Admisión: </b> Los organizadores se reservan el derecho de admisión y permanencia en el evento.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
export default PageBackToTheMT; 